<template>
  <div class="moneylogView">
    <van-sticky>
      <van-nav-bar :title="$t('信用记录')" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <van-list v-model="loading" :finished="finished" loading-text="..." :finished-text="$t('没有更多了')" @load="onLoad">
      <van-cell v-for="(item, key) in list" :key="key" class="xiax_item">
        <div class="icon tag0">{{ $t('信') }}</div>
        <div class="left">
          <span class="desc">{{ $t('信用积分') }}:{{ item.balance }}</span>
          <span class="money">{{ item.money }}</span>
        </div>
        <div class="right">
          <span class="time">{{ item.create_time }}</span>
          <span class="status">{{ item.desc }}</span>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { formatDate } from '@/utils/func';
import { MoneyLog } from '@/api/users';
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 15,
      loading: false,
      finished: false,
      showContentStatus: [],
      status: 0,
    };
  },
  watch: {},
  methods: {
    //查看任务
    onShow(id) {
      this.$router.push({ name: 'TaskInfo', params: { id: id } });
    },
    onChange(value) {
      this.list = [];
      this.page = 1;
      this.finished = false;
    },
    onClickLeft() {
      this.$router.push('/main/user/credit');
    },
    //日志列表
    GetMoneyLog() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      MoneyLog({
        page: this.page,
        pageSize: this.pageSize,
        money_type: 2,
      })
        .then((response) => {
          this.list.push.apply(this.list, response.result);
          // 加载状态结束
          this.loading = false;
          this.page++;
          if (response.result.length == 0) {
            this.finished = true;
          }
        })
        .catch((error) => {});
    },
    onLoad() {
      this.GetMoneyLog();
    },
  },
};
</script>
<style lang="stylus">
.moneylogView
  .van-tabs__line
    background-color: #4087f1
  .van-tabs__wrap--scrollable .van-tab
    flex: 0 0 16.67% !important
    padding: 0 0.23rem
  .van-list
    margin-top: 0.4rem
    .xiax_item
      background-color: #151d31
      margin-bottom: 0.15rem
      line-height: 0.6rem
      padding: 0.15rem 0.4rem
      span
        display: block
    .van-cell__value
      display: flex
      color: #c0c4cc
      align-items: center
      .icon
        color: #fff
        width: 0.75rem
        height: 0.75rem
        line-height: 0.75rem
        text-align: center
        border-radius: 100%
        font-size: 0.325rem
        margin-right: 0.15rem
        flex: none
        overflow: hidden
      .tag0
        background-color: #dd6161
      .tag1
        background-color: #07c160
      .tag2
        background-color: #1989fa
      .left
        font-size: 0.32rem
        padding: 6px
        .desc
          font-weight: 500
        .money
          color: $specColor
          font-weight: bold
          font-size: 0.42rem
      .right
        flex: 1
        font-size: 0.34rem
        padding: 6px
        text-align: right
        .time
          font-weight: 450
</style>
